.home-index {
    display: flex;
    padding-top: 20px;
}
.home-index ul {
    width: 100%;
    display: inline-flex;
    text-align: center;
    padding: 10px;
}
.home-index>ul>li{
    width: 15%;
    list-style: none;
}

.home-index>ul>li img{
    width: 30%;
}
.text-word{
    background-color: #a2a2a242;
    /* font-size: 30px; */
    position: relative;
}
.text-word p{
    font-size: x-large;
    position: absolute;
    /* vertical-align: middle; */
    top:20%;
    width: 100%;
}
tbody th,td{
    text-align: center;
    padding: 10px;
    font-size: 30px;
    border: 3px solid white;
    cursor: pointer;
}
thead tr th{
    text-align: center;
    font-size: 20px;
    width: 12%;
}
tbody tr th{
    width: 15%;
}
tbody tr td{
    width: 12%;
}
tbody tr > td:nth-child(1){
    background-color: rgb(202, 202, 202);
}
tbody tr > td:nth-child(2){
    background-color: #ffdb4c;
}

tbody tr > td:nth-child(3){
    background-color: #c643fc;
}

tbody tr > td:nth-child(4){
    background-color: #4cd964;
}

tbody tr > td:nth-child(5){
    background-color: #ff2d55;
}

tbody tr > td:nth-child(6){
    background-color: #34aadc;
}

tbody tr > td:nth-child(7){
    background-color: #ff9500;
}

.img-audio{
    width: 5%;
}
.img-audio img{
    width: 20%;
}
.table-style {
    padding: 0px 50px;
}
.select-language{
    margin : 15px !important;
}
.select-language h3 ,h6{
    text-align: center;
}
.select-language img{
    width: 100%;
    /* display: flex; */
}
.top-background{
    background-color: coral;
}

.target-language{
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 20px;
    color: white;
    font-weight: bold;
}
.center {
    width: 90%;
    margin: auto;
    padding-bottom: 20px;
    padding-top: 20px;
}
.center input{
    width: 100%;
    padding: 20px;
    border-radius: 30px;
    border: 2px solid white;
    background-color: #fbb8a0;
}
.word-content {
    text-align: center;
}
.content hr {
    border-color: #383838 !important;
}
.home-index ul>li img{
    cursor: pointer;
}
.rhap_container {
    display: none !important
}
@media screen and (max-width: 640px) {
    .table-style {
        padding: 0px 0px;
    }
    .text-word {
        background-color: #a2a2a242;
        font-size: 10px;
        position: relative;
    }
    .text-word p {
        font-size:14px;
        position: absolute;
        /* vertical-align: middle; */
        top: 10%;
        width: 100%;
    }
}
@media screen and (max-width: 1024px) {
    .table-style {
        padding: 0px 0px;
        
    }
    .table-style .table{
        width: 1024px !important;
    }
    .text-word {
        background-color: #a2a2a242;
        font-size: 10px;
        position: relative;
    }
    .text-word p {
        font-size:14px;
        position: absolute;
        /* vertical-align: middle; */
        top: 10%;
        width: 100%;
    }
    tbody tr td{
        width: 12%;
        font-size: 15px;
    }
    .img-audio img {
        width: 42%;
    }
    thead tr th{
        text-align: center;
        font-size: 15px;
        width: 12%;
    }
}